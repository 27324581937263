import React, { Component } from 'react';
import { Button, Menu, List, Modal } from 'antd';
import ScheduledTaskDetail from './ScheduledTaskDetail';
import ProjectDetail from './ProjectDetail';
import SessionContext, { getSecurityHeaders } from '../../SessionContext';
import moment from 'moment';
import { Layout } from 'antd';
const { Sider } = Layout;

class Tasks extends Component {
  state = {
    selectedProject: 'inbox',
    scheduledTaskToEdit: undefined,
    projectToEdit: undefined,
    scheduledTasks: [],
    projects: []
  };

  componentDidMount() {
    this.fetchScheduledTasks();
    this.fetchProjects();
  }

  fetchScheduledTasks = () => {
    fetch('/api/tasks/scheduledTasks?project=' + this.state.selectedProject, {
      headers: {
        ...getSecurityHeaders(this.context)
      }
    }).then(resp => {
      resp.json().then(scheduledTasks => {
        this.setState({
          scheduledTasks
        });
      });
    });
  };

  fetchProjects = () => {
    fetch('/api/tasks/projects', {
      headers: {
        ...getSecurityHeaders(this.context)
      }
    }).then(resp => {
      resp.json().then(projects => {
        this.setState({
          projects
        });
      });
    });
  };

  renderDate(d) {
    if (!!d) {
      return ' (' + moment(d, 'YYYY-MM-DD').format('DD-MM-YYYY') + ')';
    } else {
      return undefined;
    }
  }

  markScheduledTaskAsFinised = st => {
    Modal.confirm({
      title: 'Are you sure you want to finish this task?',
      content: st.name,
      okText: 'Finish',
      onOk: () => {
        fetch('/api/tasks/finishScheduledTask', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            ...getSecurityHeaders(this.context)
          },
          body: JSON.stringify(st)
        }).then(() => {
          this.fetchScheduledTasks();
        });
      }
    });
  };

  openScheduledTask = st => {
    this.setState({
      scheduledTaskToEdit: st
    });
  };

  createNewScheduledTask = () => {
    this.setState({
      scheduledTaskToEdit: {
        project_id: this.state.selectedProject !== 'inbox' ? this.state.selectedProject : undefined
      }
    });
  };

  loadProject = project_id => {
    this.setState(
      {
        selectedProject: project_id
      },
      this.fetchScheduledTasks
    );
  };

  addProject = () => {
    this.setState({
      projectToEdit: {}
    });
  };

  projectDetailClosed = somethingChanged => {
    this.setState({ projectToEdit: undefined });
    if (somethingChanged) {
      this.fetchProjects();
    }
  };

  renderPojectItems = () => {
    return this.state.projects.map(project => (
      <Menu.Item key={project._id} onClick={() => this.loadProject(project._id)}>
        {project.name}
      </Menu.Item>
    ));
  };

  render() {
    return (
      <Layout className="rootLayout">
        <Sider width={200} theme="light">
          <Menu activeKey={this.state.selectedProject}>
            <Menu.Item key="inbox" onClick={() => this.loadProject('inbox')}>
              Inbox
            </Menu.Item>
            {this.renderPojectItems()}
            <Menu.Item onClick={this.addProject}>Add Project</Menu.Item>
          </Menu>
        </Sider>
        <Layout>
          <Button shape="circle" icon="plus" onClick={this.createNewScheduledTask} />
          <List
            dataSource={this.state.scheduledTasks}
            renderItem={st => (
              <List.Item
                actions={[
                  <Button
                    onClick={() => {
                      this.markScheduledTaskAsFinised(st);
                    }}
                  >
                    Finish
                  </Button>
                ]}
              >
                <Button
                  type="link"
                  onClick={() => {
                    this.openScheduledTask(st);
                  }}
                >
                  {st.name}
                  {this.renderDate(st.dueDate)}
                </Button>
              </List.Item>
            )}
          />
          <ScheduledTaskDetail
            value={this.state.scheduledTaskToEdit}
            onModalClose={isChanged => {
              this.setState({ scheduledTaskToEdit: undefined });
              if (isChanged) {
                this.fetchScheduledTasks();
              }
            }}
            projects={this.state.projects}
          />
          <ProjectDetail value={this.state.projectToEdit} onClose={this.projectDetailClosed} />
        </Layout>
      </Layout>
    );
  }
}
Tasks.contextType = SessionContext;

Tasks.path = '/tasks';

export default Tasks;
