import React, { Component } from 'react';
import { Icon, Button, Dropdown, Slider, Radio } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStop, faSyncAlt, faVolumeUp } from '@fortawesome/free-solid-svg-icons';
import SessionContext, { getSecurityHeaders } from '../SessionContext';
import MusicLibrary from './MusicLibrary';
import MusicPlaylist from './MusicPlaylist';
import MusicSearchLibrary from './MusicSearchLibrary';

class MusicPlayer extends Component {
  state = {
    text: '-',
    playing: false,
    ampActive: false,
    volume: 80,
    stations: [],
    source: null,
    volumePopupVisible: false,
    modus: 'radio'
  };

  componentDidMount = () => {
    fetch('/api/music/radioStation', {
      headers: {
        ...getSecurityHeaders(this.context)
      }
    }).then(resp => {
      resp.json().then(stations => {
        this.setState({
          stations: stations
        });
      });
    });

    this.refreshState();

    let eventSource = new EventSource(process.env.REACT_APP_BACKEND_BASE_URI + '/api/music/stream');
    eventSource.addEventListener('message', event => {
      this.updatePlayerStatus(JSON.parse(event.data));
    });
    this.setState({ source: eventSource });
  };

  componentWillUnmount = () => {
    this.state.source.close();
  };

  refreshState = () => {
    fetch('/api/music/status', {
      headers: {
        ...getSecurityHeaders(this.context)
      }
    }).then(resp => {
      resp.json().then(playerStatus => {
        this.updatePlayerStatus(playerStatus);
      });
    });
  };

  updatePlayerStatus(playerStatus) {
    this.setState({
      text: playerStatus.text,
      playing: playerStatus.playing,
      ampActive: playerStatus.ampActive,
      volume: Math.round(playerStatus.volume)
    });
  }

  stopMusic = async () => {
    await fetch('/api/music/stop', {
      method: 'POST',
      headers: {
        ...getSecurityHeaders(this.context)
      }
    });
  };

  playRadioStation = station => {
    fetch('/api/music/radioStation', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...getSecurityHeaders(this.context)
      },
      body: JSON.stringify({ stationId: station.id })
    });
  };

  getStationsJsx() {
    let stationsJsx = [];
    for (let station of this.state.stations) {
      stationsJsx.push(
        <Button
          key={station.id}
          onClick={this.playRadioStation.bind(this, station)}
          block
          className="musicPlayer-radiostation"
        >
          {station.name}
        </Button>
      );
    }
    return stationsJsx;
  }

  toggleVolumePopup = () => {
    this.setState({
      volumePopupVisible: !this.state.volumePopupVisible
    });
  };

  onVolumeChange = value => {
    this.setState({
      volume: Math.round(value)
    });
  };

  onAfterVolumeChange = () => {
    fetch('/api/music/volume', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...getSecurityHeaders(this.context)
      },
      body: JSON.stringify({ volume: this.state.volume })
    });
  };

  getVolumePopup() {
    return (
      <div className="musicPlayer-volumePopup">
        <Slider
          vertical
          value={this.state.volume}
          onChange={this.onVolumeChange}
          onAfterChange={this.onAfterVolumeChange}
          tooltipVisible={false}
        />
      </div>
    );
  }

  changeModus = newModus => {
    this.setState({
      modus: newModus.target.value
    });
  };

  render() {
    return (
      <div>
        <div className="musicPlayer-statusBar">
          <span className="musicPlayer-statusBar-status">
            {this.state.playing && <Icon type="check" className="musicPlayer-status--true" />}
            {!this.state.playing && <Icon type="close" className="musicPlayer-status--false" />}
            {this.state.ampActive && <Icon type="check" className="musicPlayer-status--true" />}
            {!this.state.ampActive && <Icon type="close" className="musicPlayer-status--false" />}
          </span>
          <span className="musicPlayer-statusBar-text">{this.state.text}</span>
          <span className="musicPlayer-statusBar-actions">
            <Button onClick={this.stopMusic}>
              <FontAwesomeIcon icon={faStop} />
            </Button>
            <Button onClick={this.refreshState}>
              <FontAwesomeIcon icon={faSyncAlt} />
            </Button>
            <Dropdown
              overlay={this.getVolumePopup()}
              visible={this.state.volumePopupVisible}
              overlayClassName="musicPlayer-volumePopup-overlay"
            >
              <Button onClick={this.toggleVolumePopup}>
                <FontAwesomeIcon icon={faVolumeUp} />
                &nbsp;{this.state.volume}
              </Button>
            </Dropdown>
          </span>
        </div>
        <Radio.Group value={this.state.modus} onChange={this.changeModus}>
          <Radio value="radio">Radio</Radio>
          <Radio value="library">Lib</Radio>
          <Radio value="searchLibrary">Search lib</Radio>
          <Radio value="playlist">Playlist</Radio>
        </Radio.Group>

        {this.state.modus === 'radio' && this.getStationsJsx()}
        {this.state.modus === 'library' && <MusicLibrary />}
        {this.state.modus === 'searchLibrary' && <MusicSearchLibrary />}
        {this.state.modus === 'playlist' && <MusicPlaylist />}
      </div>
    );
  }
}
MusicPlayer.contextType = SessionContext;

MusicPlayer.path = '/musicPlayer';

export default MusicPlayer;
