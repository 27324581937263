import React, { Component } from 'react';
import { UserManager } from 'oidc-client';
import { createBrowserHistory } from 'history';
import { Layout, Menu } from 'antd';
import { Switch, Route, Redirect, Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import SessionContext, { getSecurityHeaders } from './SessionContext';

import 'antd/dist/antd.css';
import './App.css';
import MusicPlayer from './modules/MusicPlayer';
import Tasks from './modules/tasks/Tasks';

const history = createBrowserHistory();

const um = new UserManager({
  authority: 'https://accounts.google.com/',
  client_id: '929940008435-3k6drqnadr09d66nbstej7s1551ra7mt.apps.googleusercontent.com',
  redirect_uri: window.origin + '/authCallback'
});

class App extends Component {
  state = {
    id_token: 'none',
    userName: 'nobody'
  };

  componentDidMount = async () => {
    const user = await um.getUser();
    if (user === null) {
      if (history.location.pathname === '/authCallback') {
        um.signinRedirectCallback().then(u => {
          this.setIdToken(u.id_token);
        });
      } else {
        this.initiateAuthentication();
      }
    } else {
      this.setIdToken(user.id_token);
    }
  };

  initiateAuthentication() {
    um.removeUser().then(() => {
      um.signinRedirect();
    });
  }

  initiateAuthenticationWithConfirm(self) {
    let ask = window.confirm('The authentication has expired, do you want to re-authenticate?');
    if (ask) {
      self.initiateAuthentication();
    }
  }

  setIdToken(idToken) {
    const parts = idToken.split('.');
    const payLoadBuf = new Buffer(parts[1], 'base64');
    const payLoad = JSON.parse(payLoadBuf.toString());
    const expDate = new Date(payLoad.exp * 1000);
    if (expDate >= new Date()) {
      console.log('token will expire at ' + expDate);
      let millisTillExpiry = expDate.getTime() - new Date().getTime();
      setTimeout(this.initiateAuthenticationWithConfirm, millisTillExpiry, this);
    } else {
      this.initiateAuthentication();
    }

    this.setState({ id_token: idToken });
    this.fetchUserName();
  }

  fetchUserName = async () => {
    const resp = await fetch('/api/general/username', {
      headers: {
        ...getSecurityHeaders(this.getSessionContext())
      }
    });
    this.setState({ userName: await resp.text() });
  };

  getSessionContext = () => {
    return {
      id_token: this.state.id_token
    };
  };

  render() {
    if (this.state.id_token === 'none') {
      return <div>'Authorizing...'</div>;
    } else {
      return (
        <Layout className="rootLayout">
          <Layout.Header>
            <Menu
              mode="horizontal"
              theme="dark"
              className="rootMenu"
              selectedKeys={[this.props.location.pathname]}
            >
              <Menu.SubMenu title="Menu">
                <Menu.Item key={MusicPlayer.path}>
                  <Link to={MusicPlayer.path}>Music</Link>
                </Menu.Item>
                <Menu.Item key={Tasks.path}>
                  <Link to={Tasks.path}>Tasks</Link>
                </Menu.Item>
              </Menu.SubMenu>
            </Menu>
            <div id="username">{this.state.userName}</div>
          </Layout.Header>
          <Layout.Content>
            <SessionContext.Provider value={this.getSessionContext()}>
              <Switch>
                <Route path={MusicPlayer.path}>
                  <MusicPlayer />
                </Route>
                <Route path={Tasks.path}>
                  <Tasks />
                </Route>
                <Route path="/">
                  <Redirect to={MusicPlayer.path} />
                </Route>
              </Switch>
            </SessionContext.Provider>
          </Layout.Content>
        </Layout>
      );
    }
  }
}

export default withRouter(App);
