import React, { useContext, useEffect, useState } from 'react';
import SessionContext, { getSecurityHeaders } from '../SessionContext';

const MusicPlaylist = () => {
  const [playList, setPlayList] = useState([]);

  const sessionContext = useContext(SessionContext);

  useEffect(() => {
    fetch('/api/music/listPlaylist', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...getSecurityHeaders(sessionContext)
      }
    })
      .then(resp => resp.json())
      .then(setPlayList);
  }, []);

  return (
    <ul>
      {playList.map(entry => (
        <li key={entry.name}>{entry.name}</li>
      ))}
    </ul>
  );
};

export default MusicPlaylist;
