import React, { useContext, useEffect, useState } from 'react';
import SessionContext, { getSecurityHeaders } from '../SessionContext';
import { Button } from 'antd';

import './MusicLibrary.scss';

const MusicLibrary = props => {
  const [currentFolder, setCurrentFolder] = useState('');
  const [fileList, setFileLst] = useState(undefined);

  const sessionContext = useContext(SessionContext);

  useEffect(() => {
    fetch('/api/music/listLibrary', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...getSecurityHeaders(sessionContext)
      },
      body: JSON.stringify({ path: currentFolder })
    })
      .then(resp => resp.json())
      .then(list => {
        setFileLst(list);
      });
  }, [currentFolder, sessionContext]);

  const goToParentFolder = () => {
    const index = currentFolder.lastIndexOf('/');
    if (index >= 0) {
      setCurrentFolder(currentFolder.substring(0, index));
    } else {
      setCurrentFolder('');
    }
  };

  const playCurrentFolder = () => {
    fetch('/api/music/playFromLibrary', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...getSecurityHeaders(sessionContext)
      },
      body: JSON.stringify({ file: currentFolder })
    });
  };

  const fileClicked = (file, append) => {
    if (file.type === 'directory') {
      setCurrentFolder(file.name);
    } else {
      let url = '/api/music/playFromLibrary';
      if (append) {
        url = '/api/music/addToPlaylist';
      }
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...getSecurityHeaders(sessionContext)
        },
        body: JSON.stringify({ file: file.name })
      });
    }
  };

  return (
    <>
      <div className="currentFolder">
        <span>{currentFolder}</span>
        {currentFolder !== '' && <Button onClick={goToParentFolder}>..</Button>}
        <Button onClick={() => playCurrentFolder()}>Play current folder</Button>
      </div>
      <ul>
        {fileList &&
          fileList.map(f => (
            <li key={f.name}>
              <Button onClick={() => fileClicked(f, false)} type="link">
                {currentFolder.length > 0 && f.name.substring(currentFolder.length + 1)}
                {currentFolder.length <= 0 && f.name}
              </Button>
              {f.type !== 'directory' && <Button onClick={() => fileClicked(f, true)}>Add</Button>}
            </li>
          ))}
      </ul>
    </>
  );
};

export default MusicLibrary;
