import React, { useContext, useEffect, useRef } from 'react';
import { Modal, Form, Input, DatePicker, Select } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import { DATEFORMAT, INTERNAL_DATEFORMAT } from '../../Constants';
import SessionContext, { getSecurityHeaders } from '../../SessionContext';
const { Option } = Select;

const convertToDateString = mom => {
  if (!!mom) {
    return mom.format(INTERNAL_DATEFORMAT);
  } else {
    return undefined;
  }
};

const convertToMoment = dateString => {
  if (!!dateString) {
    return moment(dateString, INTERNAL_DATEFORMAT);
  } else {
    return undefined;
  }
};

const ScheduledTaskDetail = props => {
  const nameRef = useRef(null);
  const sessionContext = useContext(SessionContext);

  useEffect(() => {
    if (props.value) {
      setTimeout(() => {
        nameRef.current.focus();
      }, 10);
    }
  }, [props.value]);

  const saveTask = () => {
    props.form.validateFields(errors => {
      if (!errors) {
        let newValue = { ...props.value, ...props.form.getFieldsValue() };
        newValue.dueDate = convertToDateString(props.form.getFieldValue('dueDate'));
        fetch('/api/tasks/scheduledTasks', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            ...getSecurityHeaders(sessionContext)
          },
          body: JSON.stringify(newValue)
        }).then(() => {
          props.onModalClose(true);
        });
      }
    });
  };

  const projectOptions = props.projects.map(project => (
    <Option key={project._id} value={project._id}>
      {project.name}
    </Option>
  ));

  const getFieldDecorator = props.form.getFieldDecorator;

  return (
    <Modal
      visible={!!props.value}
      onCancel={() => {
        props.onModalClose(false);
      }}
      onOk={saveTask}
      okText="Save"
    >
      <Form layout="vertical">
        <Form.Item label="Name">
          {getFieldDecorator('name', {
            rules: [{ required: true }]
          })(<Input ref={nameRef} />)}
        </Form.Item>
        <Form.Item label="Project">
          {getFieldDecorator('project_id')(<Select allowClear={true}>{projectOptions}</Select>)}
        </Form.Item>
        <Form.Item label="Due date">
          {getFieldDecorator('dueDate')(<DatePicker format={DATEFORMAT} />)}
        </Form.Item>

        {/* add actions: buy / call */}
      </Form>
    </Modal>
  );
};

const ScheduledTaskDetailWithForm = Form.create({
  mapPropsToFields: props => {
    let fields = {};
    if (!!props.value) {
      fields.name = Form.createFormField({ value: props.value.name });
      fields.dueDate = Form.createFormField({ value: convertToMoment(props.value.dueDate) });
      fields.project_id = Form.createFormField({ value: props.value.project_id });
    }
    return fields;
  }
})(ScheduledTaskDetail);

ScheduledTaskDetailWithForm.propTypes = {
  value: PropTypes.object,
  onModalClose: PropTypes.func,
  projects: PropTypes.array.isRequired
};

export default ScheduledTaskDetailWithForm;
