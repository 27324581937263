import React, { useContext, useState } from 'react';
import { Input, Button } from 'antd';
import SessionContext, { getSecurityHeaders } from '../SessionContext';

const MusicSearchLibrary = () => {
  const [searchValue, setSearchValue] = useState('');
  const [fileList, setFileLst] = useState(undefined);

  const sessionContext = useContext(SessionContext);

  const search = () => {
    fetch('/api/music/searchLibrary', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...getSecurityHeaders(sessionContext)
      },
      body: JSON.stringify({ value: searchValue })
    })
      .then(resp => resp.json())
      .then(list => {
        setFileLst(list);
      });
  };

  const fileClicked = (file, append) => {
    let url = '/api/music/playFromLibrary';
    if (append) {
      url = '/api/music/addToPlaylist';
    }
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...getSecurityHeaders(sessionContext)
      },
      body: JSON.stringify({ file: file.name })
    });
  };

  return (
    <div>
      <Input
        value={searchValue}
        onChange={v => setSearchValue(v.target.value)}
        onPressEnter={search}
      />
      <Button onClick={search}>Search</Button>
      <ul>
        {fileList &&
          fileList.map(f => (
            <li key={f.name}>
              <Button onClick={() => fileClicked(f, false)} type="link">
                {f.name}
              </Button>
              <Button onClick={() => fileClicked(f, true)}>Add</Button>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default MusicSearchLibrary;
