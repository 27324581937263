import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Input } from 'antd';
import SessionContext, { getSecurityHeaders } from '../../SessionContext';

const ProjectDetail = props => {
  const nameRef = useRef(null);
  const sessionContext = useContext(SessionContext);

  const getFieldDecorator = props.form.getFieldDecorator;

  useEffect(() => {
    if (props.value) {
      setTimeout(() => {
        nameRef.current.focus();
      }, 10);
    }
  }, [props.value]);

  const onSave = () => {
    props.form.validateFields(errors => {
      if (!errors) {
        let newValue = { ...props.value, ...props.form.getFieldsValue() };
        fetch('/api/tasks/projects', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            ...getSecurityHeaders(sessionContext)
          },
          body: JSON.stringify(newValue)
        }).then(() => {
          props.onClose(true);
        });
      }
    });
  };

  return (
    <Modal
      visible={!!props.value}
      onCancel={() => props.onClose(false)}
      onOk={onSave}
      okText="Save"
    >
      <Form layout="vertical">
        <Form.Item label="Name">
          {getFieldDecorator('name', {
            rules: [{ required: true }]
          })(<Input ref={nameRef} />)}
        </Form.Item>
      </Form>
    </Modal>
  );
};

const ProjectDetailWithForm = Form.create({
  mapPropsToFields: props => {
    let fields = {};
    if (!!props.value) {
      fields.name = Form.createFormField({ value: props.value.name });
    }
    return fields;
  }
})(ProjectDetail);

ProjectDetailWithForm.propTypes = {
  value: PropTypes.object,
  onClose: PropTypes.func
};

export default ProjectDetailWithForm;
